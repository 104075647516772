import {ActivatedRouteSnapshot, Router, RouterStateSnapshot} from '@angular/router';
import {Injectable} from '@angular/core';
import {Observable, of} from 'rxjs';
import {catchError, map, tap} from 'rxjs/operators';
import {AuthService} from '../services/auth.service';
import {AuthenticationClient} from "../swagger/generated/swagger-client";

@Injectable()
export class PartnerAuthenticationGuard {
  constructor(private router: Router, private authService: AuthService, private authenticationClient: AuthenticationClient) {
  }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    let authenticationPartnerObservable;
    let acivePartner = this.authService.activePartnerSnapshot;

    if (!acivePartner) {
      let key: string = this.authService.partnerToken;
      if (key == null) {
        /* No token is found in the localstorage */
        this.router.navigate(['authentication/partner']);
        this.authService.logoffPartner();
        sessionStorage.clear();
        sessionStorage.setItem("guard.requestedurl", state.url);
        return of(false);
      }

      authenticationPartnerObservable = this.authenticationClient.validatePartnerToken(key)
        .pipe(
          tap((res) => {
            if (!res) {
              this.router.navigate(['authentication/partner']);
              this.authService.logoffPartner();
              sessionStorage.clear();
              sessionStorage.setItem("guard.requestedurl", state.url);

              throw new Error('Token is no longer valid');
            }
          }),
          this.authService.registerReceivedPartnerToken()
        );
    } else {
      authenticationPartnerObservable = of(acivePartner);
    }

    return authenticationPartnerObservable.pipe(
      map(() => {
        return true;
        // if (!this.checkPermissions(next.data.permissions)) {
        //   this.router.navigate(['']);
        //   return false;
        // } else {
        //   return true;
        // }
      }),
      catchError(() => of(false))
    );
  }
}
